import API from 'constants/API';
import {
    FormatOptionList,
    FormatOptionsCompaniesType,
    FormatOptionsRoles,
    FormatOptionsStatus,
} from 'models/OptionsBuilder';
import { useEffect, useState } from 'react';
import { GET } from 'utils/Fetch';

/** List Options
 * useOptions coustom hook to get options
 *
 * @param getCompanies list of company source ( warehouse )
 * @param getRoles list of user roles
 * @param getStatus need param {page:'livraison' | 'colis' | 'document'
 * @param getCompanyType list of company type
 *
 */
interface OptionsProps {
    getCompanies?: boolean;
    getRoles?: boolean;
    getCompaniesType?: boolean;
    getStatus?: 'livraison' | 'colis' | 'document';
    getCentre?: boolean;
}
const useOptions = ({
    getCompanies: getCompany = false,
    getRoles = false,
    getCompaniesType = false,
    getCentre = false,
    getStatus: status,
}: OptionsProps) => {
    const [companySourceOptions, setcompanySource] = useState<{ label: string; value: string }[]>([]);
    const [statusOptions, setStatusOptions] = useState<{ label: string; value: string }[]>([]);
    const [rolesOptions, setRolesOptions] = useState<{ label: string; value: string }[]>([]);
    const [companyTypeOptions, setCompanyTypeOptions] = useState<{ label: string; value: string }[]>([]);
    const [centreLogistique, setCentreLogistique] = useState<{ label: string; value: string }[]>([]);

    const GetCompanySource = async () => {
        const resSource = await GET(API.OPTIONS_COMPANIES_SOURCE);
        setcompanySource(FormatOptionList(resSource));
    };

    const GetStatus = async () => {
        if (status) {
            const resStatus = await GET(API.OPTIONS_STATUS);
            setStatusOptions(FormatOptionsStatus(resStatus, { page: status }));
        }
    };

    const GetRoles = async () => {
        const resRoles = await GET(API.OPTIONS_ROLES);
        setRolesOptions(FormatOptionsRoles(resRoles));
    };

    const GetCompaniesType = async () => {
        const resCompaniesType = await GET(API.OPTIONS_COMPANIES_TYPE);
        setCompanyTypeOptions(FormatOptionsCompaniesType(resCompaniesType));
    };

    const GetCentreLogistique = async () => {
        const response = await GET(API.OPTIONS_CENTRE_LOGISTIQUE);
        setCentreLogistique(response.data);
    };

    useEffect(() => {
        if (getCompany) GetCompanySource();
        if (getRoles) GetRoles();
        if (status) GetStatus();
        if (getCompaniesType) GetCompaniesType();
        if (getCentre) GetCentreLogistique();
    }, []);

    return { companySourceOptions, statusOptions, rolesOptions, companyTypeOptions, centreLogistique };
};

export default useOptions;
