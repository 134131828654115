/* eslint-disable @typescript-eslint/no-explicit-any */
/**
 * @param typeId id:1 for pharmacy, id:2 for wholesale distributor
 * @param typeLabel pharmacy or wholesale distributor label
 * @param autoTour Allow to wholesale distributor have auto tour
 */
export interface Company {
    id: number | null;
    name: string;
    login: string;
    password: string | null;
    phone: string;
    phone2: string;
    complementName: string;
    email: string;
    typeId: string;
    typeLabel: string;
    createdAt: string;
    suppressedAt: string;
    autoTour: boolean;
    numeroCle: string;
    location: {
        address: string;
        address2: string;
        postalCode: string;
        city: string;

        isGpsValid: boolean;
        completeAddress: string;
        googlePlaceId: string;
        gpsLatitude: number;
        gpsLongitude: number;
    };

    tour: {
        name: string;
        id: string;
        saturday: string;
    };

    delivery: {
        order: string;
        saturday: string;
    };

    access: {
        hasKeys: string;
        keyNumber: string;
        openingHours: string;
        scheduleValid: boolean;
        comment: string;
    };

    file: {
        isWaiting: boolean;
        listFile: string[];
    };
}

const EmptyCompany: Company = {
    id: null,
    login: '',
    name: '',
    complementName: '',
    typeId: '1',
    typeLabel: '',
    email: '',
    password: null,
    phone: '',
    phone2: '',
    numeroCle: '',
    autoTour: false,
    location: {
        address: '',
        address2: '',
        postalCode: '',
        city: '',
        completeAddress: '',
        googlePlaceId: '',
        gpsLatitude: 0,
        gpsLongitude: 0,
        isGpsValid: false,
    },
    delivery: {
        order: '',
        saturday: '',
    },
    createdAt: '',
    suppressedAt: '',
    access: {
        comment: '',
        hasKeys: '0',
        openingHours: '',
        keyNumber: '',
        scheduleValid: false,
    },

    tour: {
        name: '',
        id: '',
        saturday: '',
    },
    file: {
        isWaiting: false,
        listFile: [],
    },
};

export const CompanyBuilder = (): Company => ({
    ...EmptyCompany,
});

export const FormatCompany = ({ data }: any): Company => {
    const array = data.map(
        (data: any): Company => {
            return {
                id: data.id,
                login: data.Identifiant,
                name: data.RaisonSociale,
                complementName: data.complementRS,
                typeId: `${data.fk_TiersType}`,
                typeLabel: data.libelle_fk_TiersType,
                email: data.email,
                password: null,
                phone: data.telephone1,
                phone2: data.telephone2,
                autoTour: data.tourneeAuto,
                numeroCle: data.numeroCle,
                location: {
                    address: data.Adresse1,
                    address2: data.Adresse2,
                    postalCode: data.CodePostal,
                    city: data.Ville,
                    completeAddress: data.concatAdresse,
                    googlePlaceId: data.googlePlaceId,
                    gpsLatitude: data.gpsLatitude,
                    gpsLongitude: data.gpsLongitude,
                    isGpsValid: data.isGpsValid === 0 ? false : true,
                },
                delivery: {
                    order: data._ordreLivraison,
                    saturday: data._ordreLivraisonSamedi,
                },

                access: {
                    comment: data.commentaire,
                    hasKeys: `${data.hasKeys}`,
                    openingHours: data.openingHours,
                    keyNumber: data.numeroCle,
                    scheduleValid: data.isHorairesValid === 0 ? false : true,
                },

                tour: {
                    name: data.tournees,
                    id: data._fk_tournee,
                    saturday: data._fk_tourneeSamedi,
                },
                file: {
                    listFile:
                        data.concatFilesName === ''
                            ? []
                            : data.concatFilesName.data === ''
                            ? []
                            : data.concatFilesName.data.split(','),
                    isWaiting: data.isWaitingFiles,
                },
                createdAt: data.CreatedAt,
                suppressedAt: data.SuppressedAt,
            };
        },
    );

    return array[0];
};

export const ReFormatCompany = (data: Company) => {
    let access;
    if (data.access) {
        access = {
            commentaire: data.access.comment,
            numeroCle: data.access.keyNumber === '' ? null : data.access.keyNumber,
            openingHours: data.access.openingHours,
        };
    }

    const tiers = {
        Adresse1: data.location.address,
        Adresse2: data.location.address2,
        CodePostal: data.location.postalCode,
        Identifiant: data.login,
        RaisonSociale: data.name,
        Ville: data.location.city,
        complementRS: data.complementName,
        email: data.email,
        fk_TiersType: +data.typeId,
        id: data.id === 0 ? null : data.id,
        password: data.password,
        telephone1: data.phone,
        telephone2: data.phone2,
        tourneeAuto: data.autoTour ? 1 : 0,

        ...access,
    };

    return tiers;
};

export default Company;
