interface CompanySourceOption {
    value: string;
    label: string;
    subLabel?: string;
    identifiant?: string;
}

export const FormatOptionList = (
    { data }: any,
    filter?: 'Pharmacie' | 'Grossiste-Répartiteur',
): CompanySourceOption[] =>
    data
        .filter((element: { libelle_fk_TiersType: string | undefined }) => element.libelle_fk_TiersType === filter)
        .map((element: any) => {
            return {
                value: element.id,
                label: element.RaisonSociale,
                subLabel: element.concatAdresse,
                identifiant: element.Identifiant,
            };
        });

export interface Page {
    page: 'livraison' | 'colis' | 'document';
}

// A fusionner

export const FormatOptionsStatus = ({ data }: any, { page }: Page): CompanySourceOption[] =>
    data
        .map((element: any) => {
            if (element.ForType.includes(page)) {
                return {
                    value: element.id,
                    label: element.Libelle,
                };
            }
        })
        .filter((element: any) => element);

export const FormatOptionsRoles = ({ data }: any) =>
    data.map((element: any) => ({ value: element.id, label: element.Libelle }));

export const FormatOptionsCompaniesType = ({ data }: any) =>
    data.map((element: any) => ({ value: element.id, label: element.Libelle }));

//

type ObjectSelect = {
    label: string;
    value: any;
};
export default function formatSelect(array: [], id: string, libelle: string, valueVide?: string) {
    const listeSelect: ObjectSelect[] = [];
    if (valueVide)
        listeSelect.push({
            label: valueVide,
            value: null,
        });
    for (let index = 0; index < array.length; index++) {
        listeSelect.push({
            value: array[index][id],
            label: array[index][libelle],
        });
    }
    return listeSelect;
}
